.container {
  padding: 0 2rem;
}

.main {
  min-height: 100vh;
  padding: 2rem 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer {
  display: flex;
  flex: 1;
  padding: 2rem 0;
  border-top: 1px solid #eaeaea;
  justify-content: space-between;
  align-items: center;
}

.title a {
  color: #0d76fc;
  text-decoration: none;
}

.title a:hover,
.title a:focus,
.title a:active {
  text-decoration: underline;
}

.title {
  margin: 3rem auto;
  line-height: 1;
  font-size: 2.5rem;
}

.title,
.description {
  text-align: center;
}

.description {
  margin: 0 0 2rem;
  line-height: 1.5;
  font-size: 1.5rem;
}

.grid {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 800px;
}

.card {
  margin: 1rem;
  padding: 1.5rem;
  text-align: left;
  color: inherit;
  text-decoration: none;
  border: 1px solid #eaeaea;
  border-radius: 10px;
  transition: color 0.15s ease, border-color 0.15s ease;
  max-width: 350px;
}

.card:hover,
.card:focus,
.card:active {
  color: #0d76fc;
  border-color: #0d76fc;
}

.card h2 {
  margin: 0 0 1rem 0;
  font-size: 1.2rem;
}

.card p {
  margin: 0;
  font-size: 1.2rem;
  line-height: 1.5;
}

.inventoryImage {
  height: 170px;
  width: 170px;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.inventoryCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 1em;
}

.inventoryContainer{
  display: grid;
  grid-template-columns: max-content max-content;
}

.highlight {
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
}

.balanceDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1.5em 0;
}

.balanceDivInternal {
  font-size: 2em;
  margin: 0 1em;
}

.navLinks a{
  margin: auto;
  font-size: 2em;
}

.socialIcon{
  height: 25px;
  margin-left: 1.5em
}

.logo {
  height: 1.5em;
}

.mainHeading{
  font-size: 2em;
  margin: 2em 0;
  font-family: 'Permanent Marker', sans-serif;
}

.cardHeading {
  margin: 1em 0 0 0;
  text-transform: uppercase;
  font-family: 'Permanent Marker', sans-serif;
}

.cardBody {
margin: 0.5em 0 1.5em 0;
font-family: 'Permanent Marker', sans-serif;
}

.buyOnOpensea {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.buyOnOpensea a {
  font-family: 'Permanent Marker', sans-serif;
  font-size: 1.5em;
}

.buyOnOpensea a img{
  height: 35px;
  margin-left: 0.5em;
}

.tweetSlider {
  padding-top: 5em;
  margin-top: 5em;
  border-top: 1px solid rgba(128, 128, 128, 0.2);
  /* display: flex;
  align-items: space-around ; */
}

.tweetContainer {
  width: 80vw;
}

.menuLinks {
  display: flex;
}

.menuLinks a {
  font-family: 'Permanent Marker', sans-serif;
  margin: 0 1em;
  font-size: 1.5em;
}

@media (min-width: 620px) {
  .inventoryContainer{
    grid-template-columns: max-content max-content max-content;
  }

  .logo {
    height: 2em;
  }
}

@media (min-width: 820px) {
  .inventoryContainer{
    grid-template-columns: max-content max-content max-content max-content;
  }

  .tweetSlider {
    padding-top: 5em;
    margin-top: 5em;
    border-top: 1px solid rgba(128, 128, 128, 0.2);
    display: grid;
    width: 100%;
    grid-template-columns: max-content max-content;
    gap: 5vw
  }
  
  .tweetContainer {
    width: 44vw;
  }
}

@media (min-width: 1020px) {
  .inventoryContainer{
    grid-template-columns: max-content max-content max-content max-content max-content;
  }

  .tweetSlider {
    padding-top: 5em;
    margin-top: 5em;
    border-top: 1px solid rgba(128, 128, 128, 0.2);
    display: grid;
    width: 100%;
    grid-template-columns: max-content max-content max-content;
    gap: 2vw
  }
  
  .tweetContainer {
    width: 30vw;
  }
}

@media (min-width: 1220px) {
  .inventoryContainer{
    grid-template-columns: max-content max-content max-content max-content max-content max-content;
  }
}

@media (min-width: 1420px) {
  .inventoryContainer{
    grid-template-columns: max-content max-content max-content max-content max-content max-content max-content;
  }

  .tweetSlider {
    padding-top: 5em;
    margin-top: 5em;
    border-top: 1px solid rgba(128, 128, 128, 0.2);
    display: grid;
    width: 100%;
    grid-template-columns: max-content max-content max-content max-content;
    gap: 4vw
  }
  
  .tweetContainer {
    width: 21vw;
  }
}