@import url('https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap');

html,
body {
  padding: 0;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

/* body {
  background-image: url('/static/images/gray3_filter.png');
  background-repeat: repeat;
} */

.raffleCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}


.raffleHeading {
  font-family: 'Permanent Marker', sans-serif;
  font-size: 2em;
  margin-bottom: 1em;
}


.raffleImage {
  max-width: 400px;
  border-radius: 25px;
  margin-bottom: 1em;
}


.raffleDuration {
  font-family: sans-serif;
  font-size: 1.5em;
  margin-bottom: 1em;
}


.raffleButton {
  background-color: #3BC229;
  padding: 0.5em 1em;
  font-family: 'Permanent Marker', sans-serif;
  border-radius: 25px;
  font-size: 1.5em;
}

.traitBox::-webkit-scrollbar {
  width: 1em;
}

.traitBox::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.traitBox::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.traitBox{
  margin-right: 3em;
}